// TODO: check if we use bill-payments templates for activities anywhere

import { computed } from 'vue'
import { formatDate } from '/~/utils/format/date'
import {
  Activity,
  ActivityPaymentMethods,
  useActivityListItem,
} from '/~/composables/activity'
import { useCms } from '/~/composables/cms/use-cms'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useUser } from '/~/composables/user'

export function useBillPaymentsActivityListItem(props: { item: Activity }) {
  const { user } = useUser()
  const { calculatePointsEarnedForPayment } = usePoints()
  const { ewalletLabels } = useCms()

  const {
    amount,
    logo,
    canSeeDetails,
    getPointsString,
    icon,
    iconColor,
    showPoints,
    userCommunicationTitle,
    userCommunicationSubtitle,
  } = useActivityListItem(props)

  const title = computed(() => {
    if (props.item.isTypePointsTransaction) {
      return (
        props.item.metadata?.displayText ||
        props.item.description?.[0] ||
        'Points Earned'
      )
    }

    if (props.item.isTypeUserCommunication) {
      return userCommunicationTitle.value
    }

    if (props.item.isTypeBatchOrder) {
      return props.item.description?.[0]
    }

    if (props.item.isTypePointsTransferRequest) {
      return props.item.pointsTransferRequest?.title ?? 'Points debited'
    }

    return (
      props.item.description?.[1] ||
      props.item.description?.[0] ||
      props.item.originalPayeeName ||
      props.item.payee?.name
    )
  })

  const subtitle = computed(() => {
    if (props.item.isTypeUserCommunication) {
      return userCommunicationSubtitle.value
    }

    return null
  })

  const showStatus = computed(() => {
    return props.item.status && (subtitle.value || props.item.orderNumber)
  })

  const date = computed(() =>
    formatDate('daymonthyearnumeric', props.item.happenedAt)
  )

  const airlinePoints = computed(() =>
    getPointsString({ points: props.item.airlinePoints, prefix: '+' })
  )
  const pointsTransactions = computed(() => {
    const { pointsTransactions = [] } = props.item
    const filteredPointsTransactions = pointsTransactions.filter(
      (i) => i.type === 'earn'
    )
    const isEmpty = filteredPointsTransactions.length === 0

    if (props.item.isTypeBatchOrder) {
      const points =
        filteredPointsTransactions.reduce(
          (sum, item) => sum + Number(item.allocatedValue ?? 0),
          0
        ) || 0

      return [getPointsString({ points })]
    }

    if (props.item.isTypeProgramOrder) {
      return [getPointsString({ points: props.item.points, prefix: '-' })]
    }

    if (props.item.isTypePointsTransaction && props.item.subtype === 'earn') {
      return [getPointsString({ points: props.item.total })]
    }

    if (props.item.isTypeStatementOrder) {
      const points = calculatePointsEarnedForPayment(props.item.subtotal)
      const checkValue = Math.round(points) !== 0

      return [getPointsString({ points, checkValue })]
    }

    if (isEmpty || props.item.isTypeProgramOrder) {
      return ['n/a']
    }

    return filteredPointsTransactions.map((pointsRecord) => {
      const points = pointsRecord?.allocatedValue ?? 0
      const checkValue = Number(points)

      return getPointsString({ points, checkValue })
    })
  })

  const paymentMethods = computed(() => {
    if (props.item.isTypePointsTransaction) return

    if (props.item.isTypeProgramOrder) return 'n/a'

    return ((props.item.paymentMethods ?? []) as ActivityPaymentMethods)
      .map((method) => {
        if (typeof method !== 'string' && method.brand) {
          return method.brand
        }

        const value = typeof method === 'string' ? method : method.type

        switch (value) {
          case PaymentMethodType.eWallet:
            return ewalletLabels.value.ewalletCash
          case PaymentMethodType.points:
            return 'Points'
          case PaymentMethodType.creditCard:
            return 'Credit Card'
          case PaymentMethodType.bankAccount:
            return 'Bank Account'
          case PaymentMethodType.couponCode:
            return 'Coupon code'
        }
      })
      .filter((method) => method)
      .join(' + ')
  })

  const membershipName = computed(
    () => user.value?.membershipName?.toLowerCase() ?? null
  )

  return {
    showPoints,
    icon,
    amount,
    canSeeDetails,
    date,
    iconColor,
    logo,
    membershipName,
    paymentMethods,
    pointsTransactions,
    airlinePoints,
    showStatus,
    subtitle,
    title,
  }
}
