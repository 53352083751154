<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { getMenuActiveItem } from '/~/utils/menu'
import { useActivity } from '/~/composables/activity'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'
import LayoutView from '/~/templates/bill-payments/layouts/view/layout-view.vue'
import ActivityFilter from './components/activity-filter.vue'
import ActivityList from './components/activity-list.vue'

const route = useRoute()
const { pageTitle } = useEditablePage()
const { leftMenu, uiReady } = useUI()
const { getCategories } = useActivity()
const { isBillPaymentsV15Template } = useProvider()

const activeTopMenuItem = computed(() => {
  if (!uiReady.value || !leftMenu.value) return

  return getMenuActiveItem(route, leftMenu.value)
})
const description = computed(() => activeTopMenuItem.value?.description)
const title = computed(() => activeTopMenuItem.value?.label)
const menu = computed(() => activeTopMenuItem.value?.children ?? [])

getCategories()
</script>

<template>
  <layout-view :heading="title" :menu="menu">
    <template #description>
      <div v-if="description" v-html="description" />
    </template>
    <div class="flex h-full w-full">
      <div
        class="flex h-full w-full flex-col overflow-y-auto bg-eonx-neutral-50"
        :class="{
          'mr-6 rounded-t-3xl': !isBillPaymentsV15Template,
        }"
      >
        <div class="mb-8 flex w-full justify-between space-x-5 sm:w-auto">
          <h2 class="flex items-center text-2xl text-eonx-neutral-800">
            {{ pageTitle || 'Activity' }}
          </h2>
          <activity-filter />
        </div>
        <div class="flex h-full w-full overflow-y-auto overflow-x-hidden">
          <activity-list class="w-full !p-0" />
        </div>
      </div>
    </div>
  </layout-view>
</template>
