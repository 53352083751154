<script setup lang="ts">
import { computed, ComputedRef } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { Activity } from '/~/composables/activity'
import {
  useMembership,
  usePointsPrograms,
} from '/~/templates/bill-payments/composables'
import { useBillPaymentsActivityListItem } from '/~/templates/bill-payments/composables/activity'
import { BillPaymentsMembership } from '/~/templates/bill-payments/core/membership'

const props = defineProps<{ item: Activity }>()
const { findPartner } = usePointsPrograms()

const {
  currentMembership,
}: {
  currentMembership: ComputedRef<
    BillPaymentsMembership | Record<string, unknown>
  >
} = useMembership()

const selectedPartner = computed(() => findPartner(props.item.subtype))

const {
  amount,
  canSeeDetails,
  date,
  icon,
  iconColor,
  membershipName,
  paymentMethods,
  pointsTransactions,
  airlinePoints,
  showStatus,
  subtitle,
  title,
  showPoints,
} = useBillPaymentsActivityListItem(props)
</script>

<template>
  <base-link
    :to="{ name: 'activity-details', params: { id: item.id } }"
    :active="canSeeDetails"
    class="flex min-h-20 overflow-hidden rounded-xl"
  >
    <div
      class="flex w-full space-x-2 self-stretch bg-white py-3 px-2 transition-colors duration-150 ease-in-out hover:bg-blue-100 md:items-start md:space-x-6 md:px-5"
    >
      <div class="h-12 w-12 shrink-0">
        <div
          class="flex h-full w-full items-center justify-center rounded-full"
        >
          <base-icon :svg="icon" :class="iconColor" />
        </div>
      </div>
      <div
        class="w-[20%] min-w-32 flex-shrink-0 md:w-1/4"
        data-testid="title-column"
      >
        <h3
          class="mb-[5px] truncate text-base font-bold text-eonx-neutral-800"
          :title="title"
        >
          {{ title }}
        </h3>
        <div class="truncate text-sm">
          <span :class="[item.statusLabel.color]">
            {{ item.statusLabel.text }}
          </span>
          <span v-if="showStatus" class="ml-[5px] text-eonx-neutral-600">
            #
          </span>
          <span v-if="subtitle" class="truncate text-eonx-neutral-600">
            {{ subtitle }}
          </span>
          <span v-if="item.orderNumber" class="truncate text-eonx-neutral-600">
            {{ item.orderNumber }}
          </span>
        </div>
      </div>

      <template v-if="item.isTypePointsTransferRequest">
        <div class="w-[14%] md:w-2/12" data-testid="date-column">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">Date</p>
          <p class="text-eonx-neutral-800">
            {{ date }}
          </p>
        </div>

        <div class="w-[14%] md:w-2/12" data-testid="points-column">
          <p class="mb-[5px] truncate text-sm text-eonx-neutral-600">
            Points Amount
          </p>
          <p class="text-eonx-neutral-800">
            {{ props.item.points }}
          </p>
        </div>

        <div
          class="w-[14%] flex-shrink-0"
          data-testid="source-destination-column"
        >
          <p class="mb-[5px] text-sm text-eonx-neutral-600">
            {{ props.item.pointsTransferRequest?.descriptionLabel }}
          </p>
          <p
            class="truncate text-eonx-neutral-800"
            :title="props.item.pointsTransferRequest?.descriptionValue"
          >
            {{ props.item.pointsTransferRequest?.descriptionValue }}
          </p>
        </div>

        <div class="w-[14%] md:w-2/12" data-testid="reason-column">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">Reason</p>
          <p
            class="w-[60px]] truncate leading-none text-eonx-neutral-800"
            :title="props.item.pointsTransferRequest?.reason || 'N/A'"
          >
            {{ props.item.pointsTransferRequest?.reason || 'N/A' }}
          </p>
        </div>
      </template>

      <template v-else-if="!item.isTypeUserCommunication">
        <div class="w-[14%] md:w-2/12">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">Date</p>
          <p class="text-eonx-neutral-800">
            {{ date }}
          </p>
        </div>
        <div v-if="item.isTypeProgramOrder" class="w-[14%] md:w-2/12">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">
            {{ selectedPartner?.programCurrency }}
          </p>
          <p class="text-eonx-neutral-800">
            {{ airlinePoints }}
          </p>
        </div>
        <div v-else class="w-[14%] md:w-2/12">
          <template v-if="!item.isTypeMembership">
            <p class="mb-[5px] text-sm text-eonx-neutral-600">Amount</p>
            <p class="text-eonx-neutral-800">
              {{ amount }}
            </p>
          </template>
        </div>
        <div class="w-[14%] md:w-2/12">
          <template v-if="showPoints">
            <div class="mb-[5px] flex text-sm text-eonx-neutral-600">
              <v-popover
                trigger="hover click"
                placement="top-start"
                popover-inner-class="bg-neutral-700 text-white py-[5px] px-2.5 rounded-md text-xs"
                popover-arrow-class="hidden"
              >
                <span class="flex">
                  Points
                  <base-icon
                    class="ml-[5px] text-primary"
                    :size="13"
                    svg="plain/info-circle"
                  />
                </span>

                <template #popover>
                  <p class="capitalize">{{ membershipName }} tier</p>
                  <p>{{ currentMembership?.label }}</p>
                </template>
              </v-popover>
            </div>
            <p class="flex flex-col whitespace-nowrap text-eonx-neutral-800">
              <span
                v-for="(pointsTransaction, key) in pointsTransactions"
                :key="key"
              >
                {{ pointsTransaction }}
              </span>
            </p>
          </template>
        </div>
        <div class="w-[14%] shrink-0 md:w-2/12 lg:block">
          <template v-if="paymentMethods">
            <p class="mb-[5px] truncate text-sm text-eonx-neutral-600">
              Payment method
            </p>
            <p class="truncate text-eonx-neutral-800">
              {{ paymentMethods }}
            </p>
          </template>
        </div>
      </template>

      <div class="!ml-auto w-[30px] shrink-0 self-center justify-self-end">
        <base-icon
          v-if="canSeeDetails"
          size="lg"
          svg="plain/chevron-right.v2"
          class="m-auto justify-self-center text-sm text-eonx-neutral-800"
        />
      </div>
    </div>
  </base-link>
</template>
