<script setup lang="ts">
import bottomSheet from '/~/core/bottom-sheet'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import ActivityList from './components/activity-list.vue'

function action() {
  bottomSheet.show('activity-filter')
}

const { pageTitle } = useEditablePage()
</script>

<template>
  <base-aside-page
    :title="pageTitle || 'Activity'"
    :back="{
      name: 'home',
    }"
    no-padding
    shadow
  >
    <template #action>
      <div
        class="flex h-full w-full items-center justify-center rounded-full bg-primary text-white"
      >
        <base-button
          icon="heroicons/mini/adjustments-horizontal"
          :size="24"
          :padding="3"
          alt="activity filter"
          @click="action"
          @keyup.enter="action"
          @keyup.space="action"
        />
      </div>
    </template>
    <activity-list />
  </base-aside-page>
</template>
